<template>
    <!-- 车辆管理，查询停车订单 -->
    <div class="main">
        <!-- 头部搜索栏 -->
        <el-form class="header main" label-width="120px">
            <el-form-item label="收费时间：" class="formBox">
                <el-date-picker
                        v-model="paymentTime"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        type="datetimerange"
                        range-separator="至"
                        class="search-input"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        placeholder="请选择收费时间"
                        @change="paymentTimeChange()"
                />
            </el-form-item>
            <el-form-item label="车牌号：" class="formBox">
                <el-input
                        placeholder="请输入车牌号"
                        class="search-input"
                        clearable
                        v-model.trim="searchObj.plateNumber"
                ></el-input>
            </el-form-item>
            <el-form-item label="支付类型：" class="formBox">
                <el-select v-model="searchObj.payType" clearable>
                    <el-option
                            v-for="item in payTypeList"
                            :key="item.value"
                            :label="item.name"
                            :value='item.value'
                    >
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="停车场名称：" class="formBox" >
                <el-select v-model="searchObj.parkingName" clearable>
                    <el-option
                            v-for="item in parkingNameList"
                            :key="item.value"
                            :label="item.name"
                            :value='item.name'
                    >
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="快捷查询：">
                <el-tabs v-model="activeName" @tab-click="handleClick">
                    <el-tab-pane label="今日" name="1"></el-tab-pane>
                    <el-tab-pane label="昨日" name="2"></el-tab-pane>
                    <el-tab-pane label="本周" name="3"></el-tab-pane>
                    <el-tab-pane label="上周" name="4"></el-tab-pane>
                    <el-tab-pane label="本月" name="5"></el-tab-pane>
                    <el-tab-pane label="上月" name="6"></el-tab-pane>
                </el-tabs>
            </el-form-item>
            <el-button type="primary" icon="el-icon-search" @click="getData(1)" class="buttonsit">查询</el-button>
            <el-button type="primary" icon="el-icon-refresh" @click="refresh()" class="buttonsit">重置</el-button>
            <el-button type="primary" icon="el-icon-download" @click="exportFile()" class="buttonsit">导出</el-button>
        </el-form>
        <div class="body main">
            <el-table
                    :data="tableData" border>
                <el-table-column prop="body" label="停车信息" width="320"/>
                <el-table-column prop="timeEnd" label="支付完成时间" width="160"/>
                <el-table-column prop="useIntegralAmount" label="抵扣积分金额" width="110" align="center"/>
                <el-table-column prop="useIntegral" label="抵扣积分" width="80" align="center"/>
                <el-table-column prop="totalFee" label="实付金额" width="80" align="center"/>
                <el-table-column prop="preferentialAmount" label="优惠金额" width="80" align="center"/>
                <el-table-column prop="originalAmount" label="产生费用" width="80" align="center"/>
                <el-table-column prop="extendOrderNumber" label="关联订单号" width="180"/>
                <el-table-column prop="outTradeNo" label="商户订单号" width="200"/>
                <el-table-column prop="transactionId" label="微信支付订单号" width="240"/>
                <el-table-column prop="createTime" label="创建时间" width="160"/>
                <el-table-column prop="payType" label="缴费类型" width="80">
                    <template slot-scope="scope">
                        <span v-if="scope.row.payType == '2'">场内支付</span>
                        <span v-else-if="scope.row.payType == '3'">闸口支付</span>
                    </template>
                </el-table-column>
                <el-table-column prop="entryTime" label="入场时间" width="160"/>
                <el-table-column prop="landSenseDate" label="离场时间" width="160"/>
                <el-table-column prop="parkingCode" label="停车场编码" width="140"/>
                <el-table-column prop="parkingName" label="停车场名称" width="190">
                    <template slot-scope="scope">
                        <span v-if="scope.row.parkingName">{{scope.row.parkingName}}</span>
                        <span v-else>{{scope.row.body ? scope.row.body.split('-')[1] : ''}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="watchhouseCode" label="出场闸口" width="80" align="center"/>
                <el-table-column prop="watchhouseName" label="出场名称" width="120"/>
                <el-table-column prop="cdmtype" label="会员类型" width="120">
                    <template slot-scope="scope">
                        <span v-if="scope.row.cdmtype == '18'">金卡</span>
                        <span v-else-if="scope.row.cdmtype == '20'">钻卡</span>
                        <span v-else-if="scope.row.cdmtype == '23'">铂金卡</span>
                    </template>
                </el-table-column>
            </el-table>
            <el-table
             :data="accountDate" border :show-header='false'>
                <el-table-column prop="body" width="320">
                    <span style="color:red">合计</span>
                </el-table-column>
                <el-table-column prop="" width="160"></el-table-column>
                <el-table-column prop="useIntegralAmountSUM" width="110">
                </el-table-column>
                <el-table-column prop="useIntegralSUM" width="80">
                </el-table-column>
                <el-table-column prop="totalFeeSUM" width="80">
                </el-table-column>
                <el-table-column prop="preferentialAmountSUM" width="80">
                </el-table-column>
                <el-table-column prop="originalAmountSUM" width="80">
                </el-table-column>
                <el-table-column prop="" width="180"></el-table-column>
                <el-table-column prop="" width="200"></el-table-column>
                <el-table-column prop="" width="240"></el-table-column>
                <el-table-column prop="" width="160"></el-table-column>
                <el-table-column prop="" width="80"></el-table-column>
                <el-table-column prop="" width="160"></el-table-column>
                <el-table-column prop="" width="160"></el-table-column>
                <el-table-column prop="" width="140"></el-table-column>
                <el-table-column prop="" width="190"></el-table-column>
                <el-table-column prop="" width="80"></el-table-column>
                <el-table-column prop="" width="120"></el-table-column>
            </el-table>
            <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="pageNumber"
                    :page-sizes="[10,20,50,100]"
                    :page-size="pageSize"
                    layout="total,sizes,prev,pager,next, jumper"
                    :total="total">
            </el-pagination>
        </div>
    </div>
</template>
<script>
    import Pagination from "@/components/Pagination/index";
    import {downFile2} from "@/utils/index.js";
    import {
        queryParkingInfo,
        parkingInfoStatistics,
    } from "@/api/service_center/parking"

    export default {
        name: "parking_fee",
        components: {
            Pagination
        },
        data() {
            return {
                payTypeList: [
                    {
                        value: 3,
                        name: '闸道口支付'
                    },
                    {
                        value: 2,
                        name: '场内支付'
                    },
                ],
                parkingNameList: [
                    {
                        value: 1,
                        name: '丽日花园（丽日湖畔生活）'
                    },
                    {
                        value: 2,
                        name: '双子星江北店'
                    },
                    {
                        value: 3,
                        name: '丽日银座（丽日生活汇）'
                    },
                    {
                        value: 4,
                        name: '丽日中心城停车场'
                    },
                    {
                        value: 5,
                        name: '湖逸家园'
                    },
                ],
                searchObj: {
                    plateNumber: '',
                    payType: '',
                    entryStartTime: '',
                    entryEndTime: '',
                    // landSenseStartDate: '',
                    // landSenseEndDate: '',
                    payStartTime: '',
                    payEndTime: '',
                    parkingName: '',
                    parkingCode: '',
                    exportCode: '',
                },
                tableData: [],
                //分页
                pageNumber: 1,
                pageSize: 10,
                total: 0,
                dealStatusList: [
                    {
                        status: 0,
                        name: "未支付"
                    },
                    {
                        status: 1,
                        name: "已支付"
                    }
                ],
                paymentTime: ['', ''],
                entryTime: ['', ''],
                landSenseDate: ['', ''],
                createTime: ['', ''],
                useIntegralAmountsSUM: '',
                useIntegralSUM: '',
                totalFeeSUM: '',
                preferentialAmountSUM: '',
                originalAmountSUM: '',
                accountDate:[],
                activeName:'',
            }
        },
        created() {
        },
        mounted() {
            this.iniDate()
            this.getData();
        },
        methods: {
            // iniDate()
            iniDate(){
                let roles = this.$store.state.user
                if(roles.storeId == 1){
                    this.parkingNameList = [{
                        value: 2,
                        name: '双子星江北店'
                    },]
                    this.searchObj.parkingName = '双子星江北店'
                }else if(roles.storeId == 2){
                    this.parkingNameList = [{
                        value: 1,
                        name: '丽日花园（丽日湖畔生活）'
                    },]
                    this.searchObj.parkingName = '丽日花园（丽日湖畔生活）'
                }else if(roles.storeId == 4){
                    this.parkingNameList = [{
                        value: 3,
                        name: '丽日银座（丽日生活汇）'
                    },]
                    this.searchObj.parkingName = '丽日银座（丽日生活汇）'
                }else if(roles.storeId == 3){
                    this.parkingNameList = [{
                        value: 5,
                        name: '湖逸家园'
                    },]
                    this.searchObj.parkingName = '湖逸家园'
                }else if(roles.storeId == 5){
                    this.parkingNameList = [{
                        value: 4,
                        name: '丽日中心城停车场'
                    },]
                    this.searchObj.parkingName = '丽日中心城停车场'
                }else{
                    this.searchObj.parkingName = ''
                }
            },
            // 获取数据
            getData(e) {
                if (e) {
                    this.pageNumber = 1
                }
                let params = {
                    plateNumber: this.searchObj.plateNumber,
                    payType: this.searchObj.payType,
                    entryTime: this.entryTime,
                    landSenseDate: this.landSenseDate,
                    payStartTime: this.searchObj.payStartTime,
                    payEndTime: this.searchObj.payEndTime,
                    parkingLot: this.searchObj.parkingLot,
                    parkingCode: this.searchObj.parkingCode,
                    exportCode: this.searchObj.exportCode,
                    pageSize: this.pageSize,
                    pageNumber: this.pageNumber,
                    parkingName: this.searchObj.parkingName,
                }
                console.log(params)
                queryParkingInfo(params).then((res) => {
                    if (res.data.code == 200) {
                        this.tableData = res.data.body.list
                        this.total = Number(res.data.body.total)
                    }
                }).catch((e) => {
                    console.log(e)
                }).finally(()=>{
                    parkingInfoStatistics(params).then((res) => {
                        console.log(res)
                        this.accountDate = []
                        this.accountDate.push(res.data.body)
                        console.log(this.accountDate)
                        if (res.data.code == 200) {
                            this.useIntegralAmountsSUM = res.data.body.useIntegralAmountsSUM
                            this.useIntegralSUM = res.data.body.useIntegralSUM
                            this.totalFeeSUM = res.data.body.totalFeeSUM
                            this.preferentialAmountSUM = res.data.body.preferentialAmountSUM
                            this.originalAmountSUM = res.data.body.originalAmountSUM
                        }
                    }).catch((e) => {
                        console.log(e)
                    })
                })



            },
            // 重置
            refresh() {
                this.searchObj.plateNumber = '';
                this.searchObj.payType = '';
                this.searchObj.entryTime = '';
                this.searchObj.landSenseDate = '';
                this.searchObj.payStartTime = '';
                this.searchObj.payEndTime = '';
                this.searchObj.parkingLot = '';
                this.searchObj.parkingCode = '';
                this.searchObj.exportCode = '';
                this.pageNumber = 1;
                this.pageSize = 10;
                this.paymentTime = ['', ''];
                this.createTime = ['', ''];
                this.activeName = ''
                this.iniDate()
                this.getData()
            },
            exportFile() {
                let params = {
                    plateNumber: this.searchObj.plateNumber,
                    payType: this.searchObj.payType,
                    entryTime: this.entryTime,
                    landSenseDate: this.landSenseDate,
                    payStartTime: this.searchObj.payStartTime,
                    payEndTime: this.searchObj.payEndTime,
                    parkingLot: this.searchObj.parkingLot,
                    parkingCode: this.searchObj.parkingCode,
                    exportCode: this.searchObj.exportCode,
                    pageSize: this.pageSize,
                    pageNumber: this.pageNumber,
                    sheetName: "停车缴费信息",
                    parkingName:this.searchObj.parkingName,

                }
                downFile2(process.env.VUE_APP_BASE_API + '/small-inteface/parking/export', '停车缴费信息', params, 'post').then((data) => {
                    if (!data) {
                        this.$message.warning("文件下载失败")
                        return
                    }
                    if (typeof window.navigator.msSaveBlob !== 'undefined') {
                        window.navigator.msSaveBlob(new Blob([data], {type: 'application/vnd.ms-excel'}), fileName + '.xls')
                    } else {
                        let url = window.URL.createObjectURL(new Blob([data], {type: 'application/vnd.ms-excel'}))
                        let link = document.createElement('a')
                        link.style.display = 'none'
                        link.href = url
                        link.setAttribute('download', 'excel导出文件.xls')
                        document.body.appendChild(link)
                        link.click()
                        document.body.removeChild(link); //下载完成移除元素
                        window.URL.revokeObjectURL(url); //释放掉blob对象
                    }
                })
            },
            // 分页--size变化
            handleSizeChange(e) {
                this.pageSize = e
                this.getData();
            },
            // 分页--页码变化
            handleCurrentChange(e) {
                this.pageNumber = e
                this.getData();
            },
            // 查询---支付时间变化
            paymentTimeChange() {
                console.log(this.paymentTime)
                let tempArray = JSON.parse(JSON.stringify(this.paymentTime))
                console.log(tempArray)
                this.searchObj.payStartTime = tempArray[0]
                this.searchObj.payEndTime = tempArray[1]
            },
            entryTimeChange() {
                console.log(this.entryTime)
                let tempArray = JSON.parse(JSON.stringify(this.entryTime))
                console.log(tempArray)
                this.searchObj.entryStartTime = tempArray[0]
                this.searchObj.entryEndTime = tempArray[1]
            },
            landSenseDateChange() {
                console.log(this.landSenseDate)
                let tempArray = JSON.parse(JSON.stringify(this.landSenseDate))
                console.log(tempArray)
                this.searchObj.landSenseStartDate = tempArray[0]
                this.searchObj.landSenseEndDate = tempArray[1]
            },
            // 查询---创建时间变化
            createTimeChange() {
                console.log(this.createTime)
                let tempArray = JSON.parse(JSON.stringify(this.createTime))
                this.searchObj.createStartTime = tempArray[0]
                this.searchObj.createEndTime = tempArray[1]
            },
            // 快捷查询日期tabs
            handleClick(e){
                const Milliseconds = 24*60*60*1000
                let timeNow = new Date()
                let theYear = timeNow.getFullYear()
                let theMonth = timeNow.getMonth()+1
                let theDay = timeNow.getDate()
                let date = theYear+'/'+theMonth+'/'+theDay
                let todayMilliseconds = new Date(date).getTime()
                let tempArr = []
                if(this.activeName == 1){//查询今日
                  tempArr.push(this.getformatTime(todayMilliseconds))
                  tempArr.push(this.getformatTime(todayMilliseconds+Milliseconds-1))
                }else if(this.activeName == 2){//查询昨日
                  tempArr.push(this.getformatTime(todayMilliseconds-Milliseconds))
                  tempArr.push(this.getformatTime(todayMilliseconds-1))
                }else if(this.activeName == 3){//查询本周
                  let theWeek = timeNow.getDay() == 0 ? 7 : timeNow.getDay()
                  tempArr.push(this.getformatTime(todayMilliseconds-(theWeek-1)*Milliseconds))
                  tempArr.push(this.getformatTime(todayMilliseconds+Milliseconds-1))
                }else if(this.activeName == 4){//查询上周
                  let theWeek = timeNow.getDay() == 0 ? 7 : timeNow.getDay()
                  tempArr.push(this.getformatTime(todayMilliseconds-(theWeek+6)*Milliseconds))
                  tempArr.push(this.getformatTime(todayMilliseconds-(theWeek-1)*Milliseconds-1))
                }else if(this.activeName == 5){//查询本月
                  let monthStart = new Date(theYear+'/'+theMonth+'/01').getTime()
                  tempArr.push(this.getformatTime(monthStart))
                  tempArr.push(this.getformatTime(todayMilliseconds+Milliseconds-1))
                }else if(this.activeName == 6){//查询上月
                  let monthStart = new Date(theYear+'/'+theMonth+'/01').getTime()
                  let monthEnd = new Date(theYear+'/'+theMonth+'/01').getTime()
                  if(theMonth == 1){
                      monthStart = new Date((theYear-1)+'/12/01').getTime()
                  }else{
                      monthStart = new Date(theYear+'/'+(theMonth-1)+'/01').getTime()
                  }
                  tempArr.push(this.getformatTime(monthStart))
                  tempArr.push(this.getformatTime(monthEnd-1))
                }
                this.paymentTime = tempArr
                this.searchObj.payStartTime = this.paymentTime[0]
                this.searchObj.payEndTime = this.paymentTime[1]
                this.getData()
            },
            // 日期格式转换
            getformatTime(e){
                let time = new Date(e)
                let newYear = time.getFullYear()
                let newMonth = time.getMonth()+1
                newMonth = newMonth < 10 ? ('0'+newMonth.toString()) : newMonth
                let newDay = time.getDate()
                newDay = newDay < 10 ? ('0'+newDay.toString()) : newDay

                let newHours= time.getHours()
                newHours = newHours < 10 ? ('0'+newHours.toString()) : newHours

                let newMinutes = time.getMinutes()
                newMinutes = newMinutes < 10 ? ('0'+newMinutes.toString()) : newMinutes

                let newMilliseconds = time.getSeconds()
                newMilliseconds = newMilliseconds < 10 ? ('0'+newMilliseconds.toString()) : newMilliseconds
                return  newYear+'-'+newMonth+'-'+newDay+' '+newHours+':'+newMinutes+':'+newMilliseconds
            }
        }
    };
</script>
<style lang="scss" scoped>
    .main {
        width: 100%;
    }

    .header {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        align-items: flex-start;
        margin-bottom: 12px;
        padding: 12px 0 12px;
        border-bottom: 1px solid grey;

        .formBox {
            width: 25%;
            margin-bottom: 12px;
            .label {
                margin-left: 24px;
                width: 140px;
                min-width: 90px;
                display: block;
                float: left;
                line-height: 40px;
                text-align: right;
            }

            .search-input {
                width: 100%;
                float: left;
            }
        }

        .formBox1 {
            width: 50%;
            margin-bottom: 12px;

            .el-date-editor {
                width: 100%;
            }
        }
    }

    .body {
        width: 96%;
        margin: 0 auto;
    }

    .el-input {
        width: 100%
    }
    .buttonsit{
        margin: 0px 24px;
    }
</style>
